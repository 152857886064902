import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Définition du type RowObj pour les données de la table
type RowObj = {
  name: string;
  status: string;
  date: string;
  progress: number;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);

  let defaultData = tableData;
  const [data, setData] = React.useState(() => [...defaultData]);

  // Définition des colonnes de la table
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">PRODUIT</p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            STATUT
          </p>
      ),
      cell: (info) => (
          <div className="flex items-center">
            {info.getValue() === "Approuvé" ? (
                <MdCheckCircle className="text-green-500 me-1 dark:text-green-300" />
            ) : info.getValue() === "Désactivé" ? (
                <MdCancel className="text-red-500 me-1 dark:text-red-300" />
            ) : info.getValue() === "Erreur" ? (
                <MdOutlineError className="text-amber-500 me-1 dark:text-amber-300" />
            ) : null}
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {info.getValue()}
            </p>
          </div>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">DATE</p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
      ),
    }),
    columnHelper.accessor("progress", {
      id: "progress",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            PROGRESSION
          </p>
      ),
      cell: (info) => (
          <div className="flex items-center">
            <Progress width="w-[108px]" value={info.getValue()} />
          </div>
      ),
    }),
  ];

  // Configuration de la table avec les données et les colonnes
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Tableau des Commandes de Vente
          </div>
          <CardMenu />
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                        <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {header.column.getIsSorted() === 'asc' ? '▲' : header.column.getIsSorted() === 'desc' ? '▼' : ''}
                          </div>
                        </th>
                    );
                  })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                              <td
                                  key={cell.id}
                                  className="min-w-[150px] border-white/0 py-3 pr-4"
                              >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                              </td>
                          );
                        })}
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
  );
}
