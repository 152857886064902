type RowObj = {
	name: string;
	tech: string[];
	date: string;
	progress: number;
};

const tableDataComplex: RowObj[] = [
	{
		name: 'Turbo GT2260V Installation',
		tech: ['BMW', 'Audi', 'Volkswagen'],
		date: '12 Jan 2024',
		progress: 75.5
	},
	{
		name: 'Optimisation Turbo K04',
		tech: ['BMW'],
		date: '21 Feb 2024',
		progress: 35.4
	},
	{
		name: 'Mise à jour système de gestion Turbo',
		tech: ['Audi', 'Volkswagen'],
		date: '13 Mar 2024',
		progress: 25
	},
	{
		name: 'Inspection et nettoyage Turbo VGT',
		tech: ['BMW', 'Audi', 'Volkswagen'],
		date: '24 Jan 2024',
		progress: 100
	},
	{
		name: 'Diagnostic du Turbo GT1749V',
		tech: ['Audi', 'Volkswagen'],
		date: '24 Oct 2022',
		progress: 75.5
	},
	{
		name: 'Réglage électronique du Turbo',
		tech: ['BMW', 'Audi', 'Volkswagen'],
		date: '24 Oct 2022',
		progress: 75.5
	},
	{
		name: 'Installation du Turbo GT2056V',
		tech: ['BMW', 'Audi', 'Volkswagen'],
		date: '12 Jan 2024',
		progress: 75.5
	},
	{
		name: 'Optimisation système Turbo',
		tech: ['BMW'],
		date: '21 Feb 2024',
		progress: 35.4
	},
	{
		name: 'Révision complète Turbo',
		tech: ['Audi', 'Volkswagen'],
		date: '13 Mar 2024',
		progress: 25
	},
	{
		name: 'Test de performance Turbo',
		tech: ['BMW', 'Audi', 'Volkswagen'],
		date: '24 Jan 2024',
		progress: 100
	},
	{
		name: 'Calibrage Turbo haute performance',
		tech: ['Audi', 'Volkswagen'],
		date: '24 Oct 2022',
		progress: 75.5
	}
];

export default tableDataComplex;
