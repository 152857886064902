type RowObj = {
	name: string;
	progress: string;
	quantity: number;
	date: string;
};

const tableDataColumns: RowObj[] = [
	{
		name: 'Turbo Garrett 11658512454',
		quantity: 2458,
		progress: '17.5%',
		date: '12 Jan 2024',
	},
	{
		name: 'Turbo BorgWarner 711112-0002',
		quantity: 1485,
		progress: '10.8%',
		date: '21 Feb 2024',
	},
	{
		name: 'Turbo Mitsubishi TD04',
		quantity: 1024,
		progress: '21.3%',
		date: '13 Mar 2024',
	},
	{
		name: 'Turbo Holset HX35',
		quantity: 858,
		progress: '31.5%',
		date: '24 Jan 2024',
	},
];

export default tableDataColumns;
