type RowObj = {
	name: string;
	status: string;
	date: string;
	progress: number;
};

const tableDataComplex: RowObj[] = [
	{
		name: 'Turbo 11658512454',
		progress: 85.5,
		status: 'Approuvée',
		date: '10 Sep 2024',
	},
	{
		name: 'Turbo 711112-0002',
		progress: 60.0,
		status: 'En cours',
		date: '15 Aug 2024',
	},
	{
		name: 'Kit crépine 90cv',
		progress: 30.0,
		status: 'Erreur',
		date: '22 Jul 2024',
	},
	{
		name: 'Turbo 795110-5005S',
		progress: 100.0,
		status: 'Complétée',
		date: '01 Jun 2024',
	},
	{
		name: 'Turbo 765968-5001S',
		progress: 50.0,
		status: 'En attente',
		date: '18 May 2024',
	},
	{
		name: 'Consigne 70€',
		progress: 75.0,
		status: 'Approuvée',
		date: '05 Apr 2024',
	},
	{
		name: 'Turbo 465199-5003S',
		progress: 10.0,
		status: 'Annulée',
		date: '28 Mar 2024',
	},
	{
		name: 'Turbo VB15',
		progress: 95.0,
		status: 'Approuvée',
		date: '12 Feb 2024',
	},
	{
		name: 'Turbo VVQ1',
		progress: 20.0,
		status: 'Erreur',
		date: '09 Jan 2024',
	},
	{
		name: 'Turbo 798015-5002S',
		progress: 65.0,
		status: 'En cours',
		date: '31 Dec 2023',
	}
];

export default tableDataComplex;
