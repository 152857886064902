type RowObj = {
	name: string;
	status: string;
	date: string;
	progress: number;
};
const tableDataComplex: RowObj[] = [
	{
		name: 'Turbo Audi A4',
		status: 'Approuvé',
		date: '12 Jan 2024',
		progress: 75.5,
	},
	{
		name: 'Turbo BMW 320d',
		status: 'En attente',
		date: '21 Feb 2024',
		progress: 35.4,
	},
	{
		name: 'Turbo VW Golf',
		status: 'Désactivé',
		date: '13 Mar 2024',
		progress: 25,
	},
	{
		name: 'Turbo Merc C200',
		status: 'Approuvé',
		date: '24 Jan 2024',
		progress: 100,
	},
	{
		name: 'Turbo Renault RS',
		status: 'Erreur',
		date: '24 Oct 2022',
		progress: 12.2,
	},
];

export default tableDataComplex;
