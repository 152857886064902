import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck";
import tableDataComplex from "./variables/tableDataComplex";

const Dashboard = () => {
  return (
      <div>
        {/* Carte de widget */}

        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Revenus"}
              subtitle={"€340,5"}
          />
          <Widget
              icon={<IoDocuments className="h-6 w-6" />}
              title={"Dépenses ce mois"}
              subtitle={"€642,39"}
          />
          <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Ventes"}
              subtitle={"€574,34"}
          />
          <Widget
              icon={<MdDashboard className="h-6 w-6" />}
              title={"Votre Solde"}
              subtitle={"€1 000"}
          />
          <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Nouvelles Commandes"}
              subtitle={"145"}
          />
          <Widget
              icon={<IoMdHome className="h-6 w-6" />}
              title={"Totaux"}
              subtitle={"€2 433"}
          />
        </div>

        {/* Graphiques */}

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <TotalSpent />
          <WeeklyRevenue />
        </div>

        {/* Tableaux & Graphiques */}

        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          {/* Table de Contrôle */}
          <div>
            <CheckTable tableData={tableDataCheck} />
          </div>

          {/* Graphique de Trafic & Diagramme circulaire */}

          <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
            <DailyTraffic />
            <PieChartCard />
          </div>

          {/* Tableau Complexe, Tâches & Calendrier */}

          <ComplexTable tableData={tableDataComplex} />

          {/* Graphique des Tâches & Calendrier */}

          <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
            <TaskCard />
            <div className="grid grid-cols-1 rounded-[20px]">
              <MiniCalendar />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Dashboard;
