import React from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Définition du type RowObj pour les données de la table
type RowObj = {
  name: [string, boolean];
  progress: string;
  quantity: number;
  date: string;
};

// Déclaration de l'assistant de colonne pour typer les colonnes
const columnHelper = createColumnHelper<RowObj>();

function CheckTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const defaultData = tableData;
  const [data, setData] = React.useState(() => [...defaultData]);

  // Définition des colonnes de la table
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">PRODUIT</p>
      ),
      cell: (info: any) => (
          <div className="flex items-center">
            <Checkbox
                defaultChecked={info.getValue()[1]}
                colorScheme="brandScheme"
                me="10px"
            />
            <p className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
              {info.getValue()[0]}
            </p>
          </div>
      ),
    }),
    columnHelper.accessor("progress", {
      id: "progress",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            AVANCEMENT
          </p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
      ),
    }),
    columnHelper.accessor("quantity", {
      id: "quantity",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            QUANTITÉ
          </p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">DATE</p>
      ),
      cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
      ),
    }),
  ];

  // Configuration de la table avec les données et les colonnes
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Vente par marque
          </div>

          <CardMenu />
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                        <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                    );
                  })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table
                .getRowModel()
                .rows.slice(0, 5)
                .map((row) => {
                  return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                              <td
                                  key={cell.id}
                                  className="min-w-[150px] border-white/0 py-3 pr-4"
                              >
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                              </td>
                          );
                        })}
                      </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
  );
}

export default CheckTable;
