type RowObj = {
	name: [string, boolean];
	progress: string;
	quantity: number;
	date: string;
};

const tableDataCheck: RowObj[] = [
	{
		name: ['11658512454', true],
		quantity: 464,
		progress: '75.0%',
		date: '12 Jan 2024',
	},
	{
		name: ['711112-0002', true],
		quantity: 98,
		progress: '9.8%',
		date: '21 Feb 2024',
	},
	{
		name: ['795110-5005S', true],
		quantity: 3516,
		progress: '40.5%',
		date: '13 Mar 2024',
	},
	{
		name: ['765968-5001S', true],
		quantity: 3515,
		progress: '60.0%',
		date: '24 Jan 2024',
	},
	{
		name: ['810944-5005S', true],
		quantity: 0,
		progress: '0.0%',
		date: '24 Oct 2024',
	}
];

export default tableDataCheck;
